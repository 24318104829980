'use client'
import { Button } from '@/components/ui/button'
import Link from 'next/link'
import { useEffect, useState } from 'react'

export const AcceptCookies = () => {
  const [isAccept, setIsAccept] = useState(false)


  if (isAccept) {
    return null
  }

  const handleAcceptCookies = () => {
    localStorage.setItem('accept_cookies', 'true')
    setIsAccept(true)
  }
  const handleNotCookies = () => {
    localStorage.setItem('accept_cookies', 'false')
    setIsAccept(true)
  }
  return (
    <aside className="fixed z-50 inset-x-0 bottom-4 flex items-center justify-center mt-[90vh]">
      <div className="bg-white p-4 rounded-lg shadow-lg flex gap-4 items-center">
        <h1>
          Este site usa cookies para garantir que você obtenha a melhor
          experiência
          <Link
            href="/lawyer/privacy-policy"
            className="font-bold hover:underline"
          >
            {' '}
            política de privacidade
          </Link>
        </h1>
        <Button onClick={handleAcceptCookies}>Aceitar</Button>
        <Button onClick={handleNotCookies}>Rejeitar cookies</Button>
      </div>
    </aside>
  )
}
